<script lang="ts">
  import { propertyServices } from "$utils/propertystores";
  import { message } from "$utils/help";
  import { createEventDispatcher } from "svelte";
  import PermitEntrySelectorItem from "./PermitEntrySelectorItem.svelte";

  $: items = Object.values(
    $propertyServices?.access?.items ?? $propertyServices?.entry?.items ?? {}
  );

  export let value: Record<string, boolean> = null;
  export let name: string = "entry";

  const events = createEventDispatcher<{
    change: {
      name: string;
      value: Record<string, boolean>;
    };
  }>();

  function change(name: string, newvalues: Record<string, boolean>) {
    value = newvalues;

    if (name)
      events("change", {
        name,
        value,
      });
  }
</script>

{#if items.length}
  <ul class="entry">
    {#each Object.values($propertyServices?.access?.items ?? $propertyServices?.entry?.items ?? {}) as item}
      <li class="entry">
        <PermitEntrySelectorItem
          {name}
          {item}
          {value}
          on:change={(e) => change(e.detail.name, e.detail.value)}
        />
      </li>
    {/each}
  </ul>
{:else}
  <aside class="help">
    <p>
      <a
        on:click={message("I'd like to learn more about linked entry access")}
        href="mailto:help@communityboss.com?subject=Parking&body=Please tell me more about linked entry access:"
        >I'd like to learn more</a
      >
    </p>
  </aside>
{/if}
