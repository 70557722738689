<script>
  import EntryKeypadItem from "./EntryKeypadItem.svelte";
  import Time from "./Time.svelte";
  import { title } from "$utils/text";

  export let item = null;
</script>

{#if item}
  <h1>
    {[
      item.name,
      item.keypad?.code?.length && "Entry Code",
      item.remote?.enabled && `${title(item.entry || item.action)} Button`,
      //proper(item.action),
    ]
      .filter((i) => null != i)
      .join(" with ")}
  </h1>
  <!-- {#if item.remote?.enabled}
  <h2>Remote Control</h2>
  {/if} -->
  <EntryKeypadItem item={item.keypad} />
  <ul>
    {#each Object.values(item.attempts ?? {}) as attempt}
      <li>
        {attempt.success ? title(item.entry || item.action) : "Fail"}ed <Time
          datetime={attempt.attempted}
          timezone={item.timezone}
        />
      </li>
    {/each}
  </ul>
{/if}
