<script>
  import { propertyServices, policies } from "$utils/propertystores";

  import EntryCapabilityItem from "$components/EntryCapabilityItem.svelte";
  import ItemsList from "$components/item/ItemsList.svelte";
  import PolicySummaryItem from "$components/PolicySummaryItem.svelte";

  export let property = null;
</script>

<section class="accesscontrol">
  <header>
    <h1>Access Control</h1>
  </header>
  <section>
    <header>
      <h1>Entry Points</h1>
    </header>
    <ItemsList
      class="info"
      items={Object.values(
        $propertyServices?.access?.items ??
          $propertyServices?.entry?.items ??
          {}
      )}
      types={{
        entry: EntryCapabilityItem,
      }}
    />
  </section>
  <section>
    <header>
      <h1>Policies</h1>
    </header>
    {#each Object.values($policies || {}).filter((i) => i.access || i.entry) as policy}
      <ItemsList
        class="activity"
        items={[
          policy,
          ...Object.values(policy.access?.items ?? policy.entry?.items ?? {}),
        ]}
        types={{
          policy: PolicySummaryItem,
          entry: EntryCapabilityItem,
        }}
      />
    {/each}
  </section>
</section>
