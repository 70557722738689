<script lang="ts" context="module">
  function enforcefeature(item): Feature {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          item.present.item.position.lon,
          item.present.item.position.lat,
        ],
      },
      id: item.present.item.id,
      properties: {
        type: "enforce",
        vehicle: item.vehicle.display ?? item.vehicle,
      },
    };
  }
  function enforceToGeojson(enforce): FeatureCollection {
    return {
      type: "FeatureCollection",
      features: Object.values(enforce?.items ?? {})
        .filter((item) => item.warn?.required || item.remove || item.immobilize)
        .map((enforce) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              enforce.present.item.position.lon,
              enforce.present.item.position.lat,
            ],
          },
          id: enforce.present.item.id,
          properties: {
            type: "enforce",
            vehicle:
              typeof enforce.vehicle == "string"
                ? autocreate(enforce.vehicle, enforce.scope)
                : enforce.vehicle,
          },
        })),
    };
  }
  const id = param("selected");

  function isOnlyService(item: any, service: any) {
    if (service.id) service = service.id;
    // console.log(
    //   "service",
    //   service,
    //   "items",
    //   item.permitted?.items,
    //   !!item.permitted?.items &&
    //     Object.values(item.permitted.items).every(
    //       (p) => p.source.id === service
    //     )
    // );
    return (
      !!item.permitted?.items &&
      Object.values(item.permitted.items).every((p) => p.source.id === service)
    );
  }
  function isOnlyBoss(item: any) {
    return (
      !!item.permitted?.items &&
      Object.values(item.permitted.items).every(
        (p) => p.source.type === "permit"
      )
    );
  }
</script>

<script lang="ts">
  //import VehiclesPresentHeatmap from "./VehiclesPresentHeatmap.svelte";
  import { type FeatureCollection, type Feature } from "$components/map";
  import { autocreate } from "$utils/vehicle";
  import { query } from "$utils/router";
  import { param } from "$utils/params";
  import Loading from "$components/Loading.svelte";

  import VehiclePresentEnforce from "./VehiclePresentEnforce.svelte";
  import VehicleDetection from "./VehicleDetection.svelte";
  export let present: any;
  export let enforce: any;
  export let theme = "dark";
  export let partitioned: [any[], any[], any[], any[], any[], any[]] | null =
    null;

  let selected: any | null | undefined;

  $: fc = enforceToGeojson(enforce);

  $: console.log("fc=", fc);

  $: selected = fc?.features.find((feature: Feature) => feature.id == $id);

  $: console.log("selected=", selected);
  $: console.log("enforce", enforce);

  function select(feature: Feature) {
    //console.log("select", e);
    query({ selected: feature.id }, { history: false });
    //selected = feature;
  }

  $: partitioned =
    enforce &&
    Object.values(enforce.items).reduce(
      (results, item) => {
        // if (!item.present.active) results[4].push(item);
        // else
        if (item.permitted) results[0].push(item);
        else if (!item.present.active) results[4].push(item);
        else if (item.warn?.completed) results[5].push(item);
        else if (
          item.warn?.required &&
          !Object.values(item.warned.items).length
        )
          results[1].push(item);
        else if (item.warn?.required && Object.values(item.warned.items).length)
          results[2].push(item);
        else if (item.remove || item.immobilize) results[3].push(item);
        else results[5].push(item);
        return results;
      },
      [[], [], [], [], [], []]
    );
</script>

{#if enforce?.rules.remove || enforce?.rules.immobilize}
  <section>
    {#if partitioned}
      <header><h1>Escalate</h1></header>
      <ol class="info">
        {#each partitioned[3] as item}<li>
            <VehiclePresentEnforce {item} />
          </li>
        {/each}
      </ol>
    {:else}
      <header><h1><Loading /></h1></header>
    {/if}
  </section>
{/if}
{#if enforce?.rules.violations}
  <section>
    {#if partitioned}
      <header><h1>Warn Again</h1></header>
      <ol class="info">
        {#each partitioned[2] as item}<li>
            <VehiclePresentEnforce {item} />
          </li>
        {/each}
      </ol>
    {:else}
      <header><h1><Loading /></h1></header>
    {/if}
  </section>
  <section>
    {#if partitioned}
      <header><h1>Warn First</h1></header>
      <ol class="info">
        {#each partitioned[1] as item}<li>
            <VehiclePresentEnforce {item} />
          </li>
        {/each}
      </ol>
    {:else}
      <header><h1><Loading /></h1></header>
    {/if}
  </section>
  <section>
    {#if partitioned}
      <header><h1>Warned</h1></header>
      <ol class="info">
        {#each partitioned[5] as item}<li>
            <VehiclePresentEnforce {item} />
          </li>
        {/each}
      </ol>
    {:else}
      <header><h1><Loading /></h1></header>
    {/if}
  </section>
{/if}
<section>
  {#if partitioned}
    <header><h1>Known Vehicles</h1></header>
    <ol class="info">
      {#each partitioned[0] as item}<li>
          <VehiclePresentEnforce {item} />
        </li>
      {/each}
    </ol>
  {:else}
    <header><h1><Loading /></h1></header>
  {/if}
</section>

{#each Object.values(enforce?.services?.items ?? {}) as service}
  <section>
    <header>
      <h1><data value={service.id}>{service.name} Only</data></h1>
    </header>
    <ol class="info">
      {#each Object.values(enforce.items).filter( (item) => isOnlyService(item, service) ) as item}<li
        >
          <VehiclePresentEnforce {item} />
        </li>
      {/each}
    </ol>
  </section>
{/each}
{#if Object.values(enforce?.services?.items ?? {}).length}
  <section>
    <header>
      <h1>Parking Boss Only</h1>
    </header>
    <ol class="info">
      {#each Object.values(enforce.items).filter( (item) => isOnlyBoss(item) ) as item}<li
        >
          <VehiclePresentEnforce {item} />
        </li>
      {/each}
    </ol>
  </section>
{/if}
{#if present}
  <section>
    {#if partitioned}
      <header><h1>Insufficient Confidence</h1></header>
      <ol class="info">
        {#each partitioned[4] as item}<li>
            <VehiclePresentEnforce {item} />
          </li>
        {/each}
      </ol>
    {:else}
      <header><h1><Loading /></h1></header>
    {/if}
  </section>
  <section>
    <header>
      <h1>Scans</h1>
    </header>
    <ol class="info">
      {#each Object.values(present.items) as item}<li>
          <VehicleDetection {item} />
        </li>
      {/each}
    </ol>
  </section>
  <!-- <PropertyMap
    theme={usesatellite ? "satellite" : theme || "cool"}
    property={present.scope}
    {level}
    on:level={(e) => (level = e.detail)}
    bind:levels
    interactive={true}
    overlay={false}
  >

    {#if enforce}
      {#each Object.values(enforce?.items ?? {})
        .filter((item) => item.vehicle && (item.warn?.required || item.remove || item.immobilize))
        .map( (enforce) => [enforce, enforcefeature(enforce)] ) as [item, feature]}
        <MapMarker {feature} on:click={({ detail }) => select(detail)}>
          <data
            class="enforce"
            class:selected={selected && feature.id == selected.id}
            value={item.immobilize
              ? "immobilize"
              : item.remove
                ? "remove"
                : "warn"}
          >
            <RecordItem
              url={false}
              item={typeof item.vehicle == "string"
                ? autocreate(item.vehicle, item.scope)
                : item.vehicle}
            />
          </data>
        </MapMarker>
      {/each}
    {/if}
    <form>
      <fieldset>
        <input
          type="checkbox"
          name="style"
          value="satellite"
          on:change={({ currentTarget: target }) =>
            (usesatellite = target.checked)}
        />
        <MapLevelSelect {levels} bind:level />
      </fieldset>
    </form>
  </PropertyMap> -->
  {#if selected}
    <aside></aside>
  {:else}
    <slot />
  {/if}
{/if}
