<script context="module" lang="ts">
</script>

<script lang="ts">
  import { help } from "$utils/help";

  import {
    permits,
    policies,
    externalCRMOccupancyData,
  } from "$utils/propertystores";
  import EntryItem from "$components/EntryCapabilityItem.svelte";
  import ItemsList from "$components/item/ItemsList.svelte";
  import orderBy from "lodash-es/orderBy";
  import Time from "$components/Time.svelte";
  import Loading from "$components/Loading.svelte";
  import Warning from "$components/Warning.svelte";
  import { level } from "$components/space";
  import { smscreen } from "$utils/behaviorstores";
  import PermitPolicyCreatable from "$components/permit/PermitPolicyCreatable.svelte";
  import TenantNotify from "$components/tenant/TenantNotify.svelte";

  export let property: Property;

  //$: console.log("policies=", $policies);

  $: parkingPolicies = Object.values($policies || {}).filter(
    (i) => "parking" === i.amenity
  );

  $: items = $permits?.items && Object.values($permits?.items);
</script>

<section class="parking">
  <header>
    {#if property}
      <h1>Parking</h1>

      {#if !property.parking?.enabled}
        <Warning message="Parking isn't enabled" />
      {/if}
      {#if !!property.parking?.enabled && !$smscreen}
        <a href="/properties/{property.id}/parking/map">
          <img
            alt="Map preview"
            src={`https://static.carto.communityboss.app/cdn-cgi/image/w=600,h=400,format=auto/?property=${
              property.id
            }&level=${$level ?? ""}&size=900x600`}
          />
          <dfn>Smart Parking Map</dfn></a
        >
        <!-- <PropertyMap {property} interactive={false}>
          <a href="/properties/{property.id}/parking/map"
            ><dfn>Smart Parking Map</dfn></a
          >
        </PropertyMap> -->
      {/if}
      <dl>
        <dt>Synced</dt>
        <dd><Time datetime={$permits?.generated} /></dd>
      </dl>
    {:else}
      <Loading />
    {/if}
  </header>

  <!-- loop policies -->
  {#each orderBy(parkingPolicies, ["audience.admin", "rank", "title"], ["desc", "asc", "asc"]) as policy}
    <section class="policy">
      <header>
        <h1>
          {policy.title}
          <!-- <a href={`/${policy.scope}/policies/${policy.policy}`}
            >more</a
          > -->
        </h1>
        <nav>
          <ul>
            {#if policy.audience.admin}
              <li>
                <PermitPolicyCreatable {policy} {property} />
              </li>
            {/if}
          </ul>
        </nav>
      </header>

      <ItemsList
        class="activity"
        items={Object.values(
          policy?.access?.items ?? policy?.entry?.items ?? {}
        )}
        types={{
          entry: EntryItem,
        }}
      />
      <ItemsList
        class="info"
        items={items?.filter(
          (item) => item.issued.policy?.policy === policy.policy
        )}
      />
    </section>
  {/each}
  <!-- assigned -->

  <!-- <section class="exempt">
    <header>
      <h1>
        {Object.values($policies || {}).length
          ? "Special Permits"
          : "Vehicle Permits"}
      </h1>
    </header>

    {#if true === property?.vehicles.permits}
      <nav>
        <ul>
          <li>
            <a href="permits/vehicles/new"
              >New {Object.values($policies || {}).length
                ? "Special Permit"
                : "Vehicle Permit"}</a
            >
          </li>
        </ul>
      </nav>
      <ItemsList
        class="info"
        items={items?.filter(
          (item) =>
            item.issued.by && item.exempt && !item.continuous && !item.pass
        )}
      />
    {:else}
      <aside class="empty usage history">Vehicle permits are not enabled</aside>
      <aside class="help">
        <p>
          <a
            on:click={help}
            href="mailto:help@communityboss.com?subject=Parking&body=I need help with vehicle permits:"
            >I need help with this</a
          >
        </p>
      </aside>
    {/if}
  </section>

  {#if true === property?.passes?.enabled}
    <section class="exempt">
      <header>
        <h1>Printed Passes</h1>
      </header>
      <nav>
        <ul>
          <li><a href="permits/passes/new" /></li>
        </ul>
      </nav>
      <ItemsList
        class="info"
        items={Object.values($permits?.items || {}).filter((item) => item.pass)}
      />
    </section>
  {/if} -->
  <section>
    <header><h1>Parking Types</h1></header>
    <nav>
      <ul>
        <li>
          <TenantNotify variant="parking" />
          <!-- <a
              href="https://amenitypass.app/properties/{property.id}"
              target="_blank">Amenity Pass App</a
            >
            <Copy
              value="https://amenitypass.app/properties/{property.id}"
            /> -->
        </li>
      </ul>
    </nav>
    <ItemsList class="info" items={parkingPolicies} />
  </section>
</section>
