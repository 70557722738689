<script lang="ts">
  import EntryItem from "$components/EntryItem.svelte";

  export let permit: Permit;
</script>

{#if permit}
  <dl>
    <dt>Access</dt>
    {#each Object.values(permit.access?.items ?? permit.entry?.items ?? {}) as item}
      <dd>
        <EntryItem {item} />
      </dd>
    {:else}
      <dd>No linked entry access</dd>
    {/each}
  </dl>
{/if}
